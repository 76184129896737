import React, { ComponentProps, FC, forwardRef, PropsWithChildren, useState, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import { Menu, Transition } from '@headlessui/react';
import useSWR from 'swr';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useApiHostLoc, useLocId, useZuckMode, useZmail, IHostLocApi } from '@context/HostLoc';
import { auth, logout } from '@context/firebase';
import {
  IGetResp,
  GET_STRIPE_SETUP_URL,
  GET_STRIPE_LOGIN_URL,
  GET_HOST_URL,
  GET_ZALL_HOSTS_URL,
  ICreateGetFetcher,
  IZuckData
} from '@api/index';
import { useMobile } from '@utils/responsive';
import MenuPopup from '@components/MobilePopup';
import Spinner from '@components/Spinner';

import signal from '@images/home/locations/signal.svg';
import arrowDown from '@images/home/chevron-down.svg';
import defaultAvatar from '@images/home/header/default-avatar-black.svg';
import logoutIcon from '@images/home/header/logout.svg';
import settingsIcon from '@images/home/header/settings.svg';
import supportIcon from '@images/home/header/support.svg';
import hamburger from '@images/hamburger.svg';
import admin from '@images/home/header/eye.svg';
import activeAdmin from '@images/home/header/eye-on.svg';

import * as SC from '@components/Styles';
const MenuCont = tw.div`flex flex-col border border-black rounded-sm bg-white shadow-[4px_4px_0_0_rgba(0,0,0,1)]`;
const DDCont = tw.div`flex items-start pb-4 md:pb-0`;
const DDContent = tw.div`flex py-[17.92px] px-4 justify-start items-center gap-[10.4px] w-[320px]`;
const Items = tw(Menu.Items)`absolute z-30 top-[-1px] left-[-1px] border border-black rounded-sm bg-white shadow-[4px_4px_0_0_rgba(0,0,0,1)]`;
const RelDiv = tw.div`relative`;

export const LocationDropdown: FC<{}> = () => {
  const { hostApiLocInfo } = useApiHostLoc();
  const [locId, setLocId] = useLocId();

  // create an array 0 -> n, where n = len-1, filtering out the current idx
  const idxs = [...Array(hostApiLocInfo?.locations.length).keys()].filter((a) => a !== locId);
  idxs.unshift(locId);

  return (
    <MenuCont tw="relative">
      <Menu>
        {({ open }) => (
          <>
            <DropdownHeader />
            <Transition
              show={open}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {open && (
                <Items>
                  {idxs.map((i) => {
                    const { street1, street2 } = Object(hostApiLocInfo?.locations[i].address);
                    const addr = street2 ? `${street1}, ${street2}` : (street1 as string);
                    const { online } = Object(hostApiLocInfo?.locations[i].stats);
                    const { hs_deal_id } = Object(hostApiLocInfo?.locations[i]);
                    return (
                      <Menu.Item key={hs_deal_id}>
                        <DDCont tw="pb-0 cursor-pointer" onClick={() => setLocId(i)}>
                          <DDContent>
                            <LocationItem addr={addr} online={online} />
                          </DDContent>
                        </DDCont>
                      </Menu.Item>
                    );
                  })}
                </Items>
              )}
            </Transition>
          </>
        )}
      </Menu>
    </MenuCont>
  );
};

export const AdminDropdown: FC<{}> = () => {
  const [user] = useAuthState(auth);
  const token = (user as { accessToken: string } | null)?.accessToken;
  const { hostApiLocInfo } = useApiHostLoc();
  const { first_name, last_name } = Object(hostApiLocInfo);
  const [idxs, setIdxs] = useState<number[]>([]);
  const [zId, setZId] = useState<number>(0);
  const [curName, setCurName] = useState<string>('');
  const [isZuckMode] = useZuckMode();
  const [zmail, setZmail] = useZmail();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const canGetZData = token && hostApiLocInfo && hostApiLocInfo?.is_admin && isZuckMode;
  const zkey = (canGetZData ? [token, GET_ZALL_HOSTS_URL] : null) as ICreateGetFetcher['key'];
  const { data: zHosts } = useSWR<IZuckData[]>(zkey);
  const [hosts, setHosts] = useState<IZuckData[] | undefined>(zHosts);
  const hostKey = (canGetZData ? [token, GET_HOST_URL, zmail] : null) as ICreateGetFetcher['key'];
  const { mutate, isValidating } = useSWR<IHostLocApi>(hostKey);

  // new zuck email selected? update context host data
  useEffect(() => {
    if (zmail) {
      mutate();
    }
  }, [zmail]);

  // new zuck mode host data? sort it
  useEffect(() => {
    resetSortedHostData();
  }, [zHosts]);

  // ensure dropdown indexes are synced with the host data
  useEffect(() => {
    if (hosts) {
      // create an array 0 -> n, where n = len-1, filtering out the current idx
      const indexes = [...Array(hosts?.length).keys()].filter((a) => a !== zId);
      indexes.unshift(zId);
      setIdxs(indexes);
    }
  }, [hosts]);

  // new context host data? set it as the header for dropdown
  useEffect(() => {
    setCurName(`${first_name} ${last_name}`);
    resetSortedHostData();
  }, [first_name, last_name]);

  // update dropdown values to match search term
  const [searchQ, setSearchQ] = useState<string>('');
  useEffect(() => {
    const searchedHosts = searchHosts(searchQ);
    setHosts(searchedHosts);
  }, [searchQ]);

  // search the hosts, while menu isopen
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen) {
        const {key} = event;
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        if (key === 'Delete' || key === 'Backspace') {
          setSearchQ((prev) => prev.slice(0, -1));
          resetSortedHostData(false);
          // `\p{L}`: matches any letter from any language
        } else if (key.length === 1 && key.match(/^[\p{L} .\-' ]+$/u)) {
          event.preventDefault();
          setSearchQ((prev) => prev + key);
        }
        timeoutRef.current = setTimeout(() => {
          resetSortedHostData();
        }, 4000);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      resetSortedHostData();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      resetSortedHostData();
    };
  }, [isOpen]);

  const onSelectZustomer = (id: number, email: string) => {
    setZId(id);
    setZmail(email);
    resetSortedHostData();
  };

  const searchHosts = (query: string): IZuckData[] | undefined => {
    if (!hosts) return undefined;
    const q = query.toLowerCase();
    const filtered = hosts.filter((host) => {
      const { first_name, last_name } = host;
      return `${first_name.toLowerCase()} ${last_name.toLowerCase()}`.includes(q);
    });
    return filtered.length > 0 ? filtered : [{ id: '123', email: '', first_name: 'none', last_name: '' }];
  };

  const resetSortedHostData = (resetQ: boolean = true) => {
    if (zHosts) {
      const sorted = [...zHosts].sort((a, b) => a.last_name.localeCompare(b.last_name));
      setHosts(sorted);
      if (resetQ) setSearchQ('');
    }
  };

  return (
    <RelDiv>
      <SC.TxtBase tw="absolute inset-x-0 -top-5 text-center pb-2">{searchQ ? `search: ${searchQ}` : ''}</SC.TxtBase>
      <MenuCont ref={menuRef} tw="relative" onClick={() => setIsOpen((cur) => !cur)}>
        <Menu>
          <Menu.Button tw="focus:outline-none">
            <DDCont tw="pb-0">
              <DDContent>
                <LocAddrCont>
                  <LocAddrTxt>{isValidating ? <Spinner /> : curName}</LocAddrTxt>
                </LocAddrCont>
              </DDContent>
            </DDCont>
          </Menu.Button>
          <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {isOpen && hosts && hosts.length === idxs.length && (
              <Items tw="max-h-[245px] overflow-y-auto focus:outline-none">
                {idxs.map((i) => {
                  const { email, first_name, last_name, id } = hosts[i];
                  return (
                    <Menu.Item key={id}>
                      {({ active }) => (
                        <DDCont tw="pb-0 cursor-pointer" onClick={() => onSelectZustomer(i, email)}>
                          <DDContent css={[active && tw`bg-really-brand-25`]}>
                            <LocAddrCont>
                              <LocAddrTxt>{`${first_name} ${last_name}`}</LocAddrTxt>
                            </LocAddrCont>
                          </DDContent>
                        </DDCont>
                      )}
                    </Menu.Item>
                  );
                })}
              </Items>
            )}
          </Transition>
        </Menu>
      </MenuCont>
    </RelDiv>
  );
};

const SignalCont = tw.div`w-[20.8px] h-[20.8px]`;
const ArrowCont = tw.div`w-[26px] h-[26px]`;
const LocAddrCont = tw.div`flex justify-end items-start gap-[5.2px]`;
const LocAddrTxt = tw.span`text-black text-lg font-bold leading-[1.125rem] truncate max-w-[212px]`;
const LocAddrStatus = tw.div`w-[10.4px] h-[10.4px] rounded-full`;
const Img = tw.img``;

const DropdownHeader: FC<ComponentProps<'button'>> = () => {
  const { hostApiLocInfo } = useApiHostLoc();
  const [locId] = useLocId();

  const { street1, street2 } = Object(hostApiLocInfo?.locations[locId].address);
  const addr = street2 ? `${street1}, ${street2}` : (street1 as string);
  const { online } = Object(hostApiLocInfo?.locations[locId].stats);

  return (
    <Menu.Button>
      <DDCont tw="pb-0">
        <DDContent>
          <LocationItem addr={addr} online={online} arrow />
        </DDContent>
      </DDCont>
    </Menu.Button>
  );
};

const LocationItem: FC<{ addr: string; online: boolean; arrow?: boolean }> = ({ addr, online, arrow }) => {
  return (
    <>
      <SignalCont>
        <img src={signal} />
      </SignalCont>
      <LocAddrCont>
        <LocAddrTxt>{addr}</LocAddrTxt>
        <LocAddrStatus css={[online ? tw`bg-really-success` : tw`bg-really-error`]} />
      </LocAddrCont>
      <ArrowCont>
        <Img css={[arrow ? tw`inline-block` : tw`hidden`]} src={arrowDown} />
      </ArrowCont>
    </>
  );
};

const HeaderMenuCont = tw.div`flex flex-col`;
const Item = tw.li`flex flex-row gap-1.5 cursor-pointer`; //gap-3
const Cont10 = tw.div`h-10 w-10`;
const AvatarLink = tw.a`flex items-center`;
const ArrowContHeader = tw.div`flex items-center`;
const Cont6 = tw.div`h-6 w-6`;
const UserImg = tw.img`rounded-full`;
const HeaderItems = tw(Items)`top-[-15px] left-[-51px] flex flex-col justify-end items-end pl-0 pr-4 pt-3.5 pb-7`;
const MenuSubItemsCont = tw.div`w-[120px] flex justify-center items-end pr-4 pt-4`;
const SubMenuTxt = tw(SC.TxtBase)`text-center`;
const SubMenuTxtCont = tw.div`w-[26px] h-[26px] flex items-center`;
const SubMenuBtnCont = tw.div`h-6 w-6 flex items-center`;
const HamburgerCont = tw.div`w-9 h-9`;

export const HeaderDropdown: FC<{}> = () => {
  const { hostApiLocInfo } = useApiHostLoc();
  const [user] = useAuthState(auth);
  const token = (user as { accessToken: string } | null)?.accessToken;
  const isMobile = useMobile();
  const [openMenu, setOpenMenu] = useState(false);
  const [isZuckMode, setIsZuckMode] = useZuckMode();
  const { stripe_has_ach, is_admin } = Object(hostApiLocInfo);
  // an admin user can login, and wont have any locations, will not be able to setup stripe
  const hasKey = token && typeof stripe_has_ach !== 'undefined' && hostApiLocInfo?.locations !== null;
  const stripeKey = (hasKey && !isZuckMode ? [token, stripe_has_ach ? GET_STRIPE_LOGIN_URL : GET_STRIPE_SETUP_URL] : null) as ICreateGetFetcher['key'];
  const { data } = useSWR<IGetResp>(stripeKey);
  const stripeUrl = data ? data.url : '';

  const onLogout = () => {
    logout();
  };

  if (isMobile) {
    return (
      <HeaderMenuCont>
        <HamburgerCont onClick={() => setOpenMenu(true)}>
          <img src={hamburger} />
        </HamburgerCont>
        <MobileMenu isOpen={openMenu} setIsOpen={setOpenMenu} stripeUrl={stripeUrl} />
      </HeaderMenuCont>
    );
  }

  const onBillingClick = () => {
    // NOTE temp solution, ahrefs being blocked
    if (stripe_has_ach) {
      window.open(stripeUrl, '_blank');
    } else {
      window.location.href = stripeUrl;
    }
  };

  return (
    <HeaderMenuCont tw="relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button>
              <HeaderAvatar />
            </Menu.Button>
            <Transition
              show={open}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {open && (
                <HeaderItems>
                  <Menu.Item>
                    <HeaderAvatar />
                  </Menu.Item>
                  {is_admin && (
                    <Menu.Item>
                      <MenuSubItemsCont onClick={() => setIsZuckMode((cur) => !cur)}>
                        <Item>
                          <SubMenuBtnCont>
                            <img src={isZuckMode ? activeAdmin : admin} />
                          </SubMenuBtnCont>
                          <SubMenuTxtCont>
                            <SubMenuTxt>Admin</SubMenuTxt>
                          </SubMenuTxtCont>
                        </Item>
                      </MenuSubItemsCont>
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {/* NOTE temp solution, ahrefs being blocked */}
                    <MenuSubItemsCont onClick={() => window.open('mailto:hello@really.com', '_blank')}>
                      <Item>
                        <SubMenuBtnCont>
                          <img src={supportIcon} />
                        </SubMenuBtnCont>
                        <SubMenuTxtCont>
                          <SubMenuTxt>Support</SubMenuTxt>
                        </SubMenuTxtCont>
                      </Item>
                    </MenuSubItemsCont>
                  </Menu.Item>
                  <Menu.Item>
                    <MenuSubItemsCont onClick={onBillingClick}>
                      <Item>
                        <SubMenuBtnCont>
                          <img src={settingsIcon} />
                        </SubMenuBtnCont>
                        <SubMenuTxtCont>
                          <SubMenuTxt>Billing</SubMenuTxt>
                        </SubMenuTxtCont>
                      </Item>
                    </MenuSubItemsCont>
                  </Menu.Item>
                  <Menu.Item>
                    <MenuSubItemsCont onClick={onLogout}>
                      <Item>
                        <SubMenuBtnCont>
                          <img src={logoutIcon} />
                        </SubMenuBtnCont>
                        <SubMenuTxtCont>
                          <SubMenuTxt>Logout</SubMenuTxt>
                        </SubMenuTxtCont>
                      </Item>
                    </MenuSubItemsCont>
                  </Menu.Item>
                </HeaderItems>
              )}
            </Transition>
          </>
        )}
      </Menu>
    </HeaderMenuCont>
  );
};

// eslint-disable-next-line react/display-name
const HeaderAvatar = forwardRef<HTMLLIElement, PropsWithChildren>((props, ref) => {
  // sometimes the pic image times out? need to regenerate? need to test more
  // const [user] = useAuthState(auth);
  // only show google pics for now, TODO need better checking for verify it exists with other social media logins
  // const showUserPic =  user?.photoURL &&  user?.photoURL.startsWith('https://lh3.googleusercontent.com');
  return (
    <Item ref={ref}>
      <Cont10>
        <AvatarLink href="#">
          <UserImg src={defaultAvatar} />
        </AvatarLink>
      </Cont10>
      <ArrowContHeader>
        <Cont6>
          <AvatarLink href="#">
            <img src={arrowDown} />
          </AvatarLink>
        </Cont6>
      </ArrowContHeader>
    </Item>
  );
});

const MenuDiv = tw.div`flex flex-col justify-center text-white`;
const MMList = tw.ul`flex flex-col gap-0 items-center`;
const MMItem = tw.li`font-bold text-5xl border-b-2 border-white m-2 leading-[60px]`;
const MMLink = tw.a`flex items-center`;

const MobileMenu: FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  stripeUrl: string;
}> = ({ isOpen, setIsOpen, stripeUrl }) => {
  const { hostApiLocInfo } = useApiHostLoc();
  const [, setIsZuckMode] = useZuckMode();
  const { is_admin } = Object(hostApiLocInfo);

  const onLogout = () => {
    setIsOpen(false);
    logout();
  };

  const onZClick = () => {
    setIsZuckMode((cur) => !cur);
    setIsOpen(false);
  };

  return (
    <MenuPopup isOpen={isOpen} onClose={setIsOpen}>
      <MenuDiv>
        <MMList>
          {is_admin && (
            <MMItem>
              <MMLink onClick={onZClick}>admin</MMLink>
            </MMItem>
          )}
          <MMItem>
            <MMLink href={'mailto:hello@really.com'} target="_blank" onClick={() => setIsOpen(false)}>
              support
            </MMLink>
          </MMItem>
          <MMItem>
            <MMLink href={stripeUrl} onClick={() => setIsOpen(false)}>
              billing
            </MMLink>
          </MMItem>
          <MMItem>
            <MMLink onClick={onLogout}>logout</MMLink>
          </MMItem>
        </MMList>
      </MenuDiv>
    </MenuPopup>
  );
};
