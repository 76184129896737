import React, { lazy } from 'react';
import { Redirect, Switch, useSearch } from 'wouter';

import WRoute from '@components/WRoute';
import { SWRConfigProvider } from '@api/index';
import { ApiHostLocProvider, LocIdProvider, ZuckModeProvider, ZuckEmailProvider } from '@context/HostLoc';
const Home = lazy(() => import('@views/Home'));
const Login = lazy(() => import('@views/Login'));
const Reset = lazy(() => import('@views/ForgotPassword'));

const AppRouter: React.FC = () => {
  const searchString = useSearch();
  let validate = false;
  if (searchString && searchString.split('=')[0] === 'validate') {
    validate = true;
  }

  return (
    <SWRConfigProvider>
      <ZuckModeProvider>
        <ZuckEmailProvider>
          <ApiHostLocProvider validate={validate}>
            <LocIdProvider>
              <Switch>
                <WRoute layout auth path="/" component={Home} />
                <WRoute layout login path="/login" component={Login} />
                <WRoute layout login path="/forgot-password" component={Reset} />
                <Redirect to="/" />
              </Switch>
            </LocIdProvider>
          </ApiHostLocProvider>
        </ZuckEmailProvider>
      </ZuckModeProvider>
    </SWRConfigProvider>
  );
};

export default AppRouter;
