import { initializeApp } from 'firebase/app';
import { FirebaseError } from '@firebase/util';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getAdditionalUserInfo
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { captureMessage } from '@sentry/react';
import { UseFormSetError } from 'react-hook-form';

const {
  VITE_REACT_APP_FIREBASE_API_KEY,
  VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
  VITE_REACT_APP_FIREBASE_PROJECT_ID,
  VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
  VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  VITE_REACT_APP_FIREBASE_ID,
  VITE_REACT_APP_FIREBASE_MEASUREMENT_ID
} = import.meta.env;

const firebaseConfig = {
  apiKey: VITE_REACT_APP_FIREBASE_API_KEY,
  authDomain: VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: VITE_REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: VITE_REACT_APP_FIREBASE_ID,
  measurementId: VITE_REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export type IGoogleSignin = {
  setNotFound: () => void;
};

export const signInWithGoogle = async ({ setNotFound }: IGoogleSignin) => {
  let email;
  let isRando = false;
  try {
    const res = await signInWithPopup(auth, googleProvider);
    email = res.user.email;
    const info = getAdditionalUserInfo(res);
    if (info === null || info.isNewUser) {
      // hack to not allow new users to sign up w/google
      res.user.delete();
      isRando = true;
      logout();
      setTimeout(() => setNotFound(), 500);
    }
  } catch (err) {
    console.error(err);
    if (err instanceof FirebaseError) {
      captureMessage('Failed google login attempt', {
        level: 'warning',
        extra: {
          email,
          isEmailNotInFBase: isRando,
          error: err.message
        }
      });
    }
  }
};

type ILogin = {
  email: string;
  password: string;
  setError: UseFormSetError<ISignInForm>
};

export interface ISignInForm {
  email: string;
  password: string;
  formError: string;
};

export type IReset = {
  email: string;
  setSent: () => void;
};

const errorForUser = (err: string): string => {
  // firebase is not consistent with its format, sometimes you want the first part,
  // sometimes there is no first part - so only display minimal msg
  const regex = /^Firebase:\s(.*?)(?=\s?\()/;
  const match = err.match(regex);
  if (!match || match[1] === 'Error') {
    const regex2 = /auth\/([a-zA-Z-]+)/;
    const match2 = err.match(regex2);
    return match2 ? `${match2[1]}, please try again` : 'error logging in, please try again';
  }
  return match ? `${match[1]}` : 'error logging in, please try again';
};

export const logInWithEmailAndPassword = async ({ email, password, setError }: ILogin) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    if (err instanceof FirebaseError) {
      captureMessage('Failed email/pw login attempt', {
        level: 'warning',
        extra: {
          email,
          error: err.message
        }
      });
      setError('formError', { type: 'manual', message: errorForUser(err.message.toString())});
    }
  }
};

export const sendPasswordReset = async ({ email, setSent }: IReset) => {
  try {
    await sendPasswordResetEmail(auth, email);
    setSent();
  } catch (err) {
    if (err instanceof FirebaseError) {
      captureMessage('Failed pw reset', {
        level: 'warning',
        extra: {
          email,
          error: err.message
        }
      });
      // alert(err.message);
    }
  }
};

export const logout = () => {
  signOut(auth);
};
