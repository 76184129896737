import React from 'react';
import { Redirect, RouteProps, Route } from 'wouter';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth as fbAuth } from '@context/firebase';
import Layout from './Layout';

interface Props {
  layout?: boolean;
  auth?: boolean;
  login?: boolean;
}

const WRoute: React.FC<RouteProps & Props> = ({ layout, login = false, auth, ...rest }) => {
  const [user] = useAuthState(fbAuth);

  if (auth && user === null) return <Redirect to="/login" />;

  if (!layout) return <Route {...rest} />;

  return (
    <Layout login={login}>
      <Route {...rest} />
    </Layout>
  );
};

export default WRoute;
