import React, { FC, ReactNode } from 'react';
import { SWRConfig, Middleware, BareFetcher } from 'swr';
import axios, { AxiosError, isAxiosError, AxiosRequestConfig } from 'axios';
import { setTag, setUser, captureException, setContext } from '@sentry/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User } from 'firebase/auth';
import { jwtDecode } from "jwt-decode";

const { VITE_REACT_APP_API_BASE_URL } = import.meta.env;
import { auth } from '@context/firebase';
import {
  getHostErrMsgs,
  unknownHostErrMsg,
  unknownHostErrMsg2,
  reqPayErrMsgs,
  unknownReqPayErrMsg,
  unknownReqPayErrMsg2
} from '@api/errorMessages';
import { logout } from '@context/firebase';

// endpoint reponse data types
export interface IGetResp {
  url: string;
}
export interface IValidateStripeResp {
  stripe_has_ach: boolean;
}
export interface IZuckData {
  email: string;
  first_name: string;
  last_name: string;
  id: string;
}

// endpoints
const BASE_URL = `${VITE_REACT_APP_API_BASE_URL}/api/v1`;
export const POST_CASHOUT_URL = '/host/cashout'; // -> IGetReq
export const GET_HOST_URL = '/host/info'; // -> IHostLocApi from '@context/HostLoc'
export const GET_STRIPE_SETUP_URL = '/host/payout-setup-link'; // -> IGetReq
export const GET_STRIPE_LOGIN_URL = '/host/payout-login-link'; // -> IGetReq
export const POST_STRIPE_SETUP_URL = '/host/validate-payout-account'; // -> IValidateStripe
export const GET_ZALL_HOSTS_URL = '/admin/customer'; // -> IZuckData[]

interface IHeader {
  'Content-Type': string;
  'x-jwt': string;
  [key: string]: string;
}

type IToken = string;
type IURL = string;
type IData = object;
type IZmail = string;

export interface ICreateGetFetcher {
  key: [IToken, IURL, IZmail?] | null;
}
export const createGetFetcher = async (key: ICreateGetFetcher['key']) => {
  if (!key) return null;
  const [token, url, zmail = ''] = key;
  const headers = { 'Content-Type': 'application/json', 'x-jwt': token } as IHeader;
  if (zmail) {
    headers['x-zm-email'] = zmail;
  }
  const config: AxiosRequestConfig = { baseURL: BASE_URL, method: 'GET', headers, url };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export interface ICreatePostFetcher {
  key: [IToken, IURL, IData];
}
export const createPostFetcher = async (key: ICreatePostFetcher['key']) => {
  const [token, url, data] = key;
  const headers = { 'Content-Type': 'application/json', 'x-jwt': token } as IHeader;
  const config: AxiosRequestConfig = { baseURL: BASE_URL, method: 'POST', headers, url, data };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const tokenRefreshMiddleware: Middleware = (useSWRNext) => (key, fetcher, config) => {
  const [user] = useAuthState(auth);
  if (key === null || !user) {
    return useSWRNext(key, null, config);
  }

  const newFetcher: BareFetcher<any> = async (...args: any[]) => {
    let token: string;

    // for any of our endpoints, the token is the first element in the key array
    if (Array.isArray(key) && key.length > 0 && typeof key[0] === 'string') {
      token = key[0];
    } else {
      return fetcher ? fetcher(...args) : null;
    }

    const decodedToken: { exp: number } = jwtDecode(token);
    const currentTimeInSeconds = Date.now() / 1000;

    // check if token expired over 3 hours ago
    // tokens last for 1hr, so if it expired over 2 hrs ago, logout
    if (currentTimeInSeconds - decodedToken.exp >= 7200) {
      logout();
      return useSWRNext(key, null, config);
    }
    // else if expired, get new token and update the swr key before api call is made
    else if (currentTimeInSeconds >= decodedToken.exp) {
      await user.getIdToken(true);
      token = await user.getIdToken();
      key[0] = token;
    }

    return fetcher ? fetcher(...args) : null;
  };

  return useSWRNext(key, newFetcher, config);
};

export const SWRConfigProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user] = useAuthState(auth);

  return (
    <SWRConfig
      value={{
        errorRetryInterval: 500,
        errorRetryCount: 3,
        fetcher: createGetFetcher,
        use: [tokenRefreshMiddleware],
        onError: (error, key) => {
          handleError({ key, error, user });
        }
      }}
    >
      {children}
    </SWRConfig>
  );
};

interface IErrorHandler {
  key: string; //TODO make it enum of all possible urls, such as PAY_REQ_URL
  error: Error | AxiosError;
  user: User | null | undefined;
}

const handleError = ({ key, error, user }: IErrorHandler) => {
  let msg = '';
  if (isAxiosError(error)) {
    if (error.response?.status && [400, 401, 404, 500].includes(error.response.status)) {
      const status = error.response.status as 400 | 401 | 404 | 500;
      msg = key.includes('cashout') ? reqPayErrMsgs[`${status}`] : getHostErrMsgs[`${status}`];
    } else {
      msg = key.includes('cashout') ? unknownReqPayErrMsg : unknownHostErrMsg;
    }
    setTag('request-id', error.response?.headers['x-trace-id']);
    setContext('axios error', { code: error.code, message: error.message, name: error.name });
  } else {
    msg = key.includes('cashout') ? unknownReqPayErrMsg2 : unknownHostErrMsg2;
  }
  console.log(msg);
  setTag('url', key);
  setUser({ id: user ? user.uid : '', email: user?.email ? user.email : '' });
  captureException(error);
};
