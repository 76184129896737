import React, { useEffect, FC, PropsWithChildren, Dispatch, SetStateAction, MouseEvent } from 'react';
import tw from 'twin.macro';

import mobileMenuClose from '@images/mobileMenuClose.svg';

const MenuPopupCont = tw.div`fixed flex items-center justify-center left-0 top-0 h-full w-full z-100 bg-scroll`;
const MenuFadeCont = tw.div`fixed left-0 top-0 h-full w-full bg-black`;
const MenuPopupDiv = tw.div`flex flex-col items-end h-full lg:h-auto max-h-full max-w-full`;
const CloseBtn = tw.div`fixed right-5 top-7 hover:opacity-60 transition-all duration-300 cursor-pointer p-1`;
const CloseBtnImg = tw.img`w-6 h-6`;
const MenuContent = tw.div`p-6 overflow-y-auto overflow-x-hidden w-full h-full lg:h-auto flex justify-center`;

const MenuPopup: FC<
  PropsWithChildren<{
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
  }>
> = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  const closeHandler = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClose(false);
  };

  return (
    isOpen && (
      <MenuPopupCont onClick={closeHandler}>
        <MenuFadeCont onClick={closeHandler}>
          <MenuPopupDiv>
            <CloseBtn onClick={closeHandler}>
              <CloseBtnImg src={mobileMenuClose} />
            </CloseBtn>
            <MenuContent onClick={(e) => e.stopPropagation()}>{children}</MenuContent>
          </MenuPopupDiv>
        </MenuFadeCont>
      </MenuPopupCont>
    )
  );
};

export default MenuPopup;
