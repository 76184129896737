import React, { createContext, useContext, ReactNode, FC, useState, useEffect } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAuthState } from 'react-firebase-hooks/auth';
import { setUser, captureException, captureMessage } from '@sentry/react';

import createContextState from '@utils/contextState';
import { GET_HOST_URL, POST_STRIPE_SETUP_URL, createPostFetcher, ICreatePostFetcher, ICreateGetFetcher } from '@api/index';
import { auth, logout } from '@context/firebase';

export const { Provider: LocIdProvider, useContextState: useLocId } = createContextState<number>(0);
export const { Provider: ZuckModeProvider, useContextState: useZuckMode } = createContextState<boolean>(false);
export const { Provider: ZuckEmailProvider, useContextState: useZmail } = createContextState<string>('');

interface IApiHostLocCtx {
  hostApiLocInfo: IHostLocApi | undefined;
  isApiLocLoading: boolean;
}

export const ApiHostLocCtx = createContext<IApiHostLocCtx | null>(null);

export const ApiHostLocProvider: FC<{ validate?: boolean; children: ReactNode }> = ({ validate, children }) => {
  const [user] = useAuthState(auth);
  const token = (user as { accessToken: string } | null)?.accessToken;
  const [validateFlag, setValidateFlag] = useState<boolean>(false);
  const [triggered, setTriggered] = useState<boolean>(false);
  const [hostData, setHostData] = useState<IHostLocApi | undefined>(undefined);

  const hostKey = (token ? [token, GET_HOST_URL] : null) as ICreateGetFetcher['key'];
  const { data, error, isLoading: isApiLocLoading, mutate } = useSWR<IHostLocApi>(hostKey);

  const stripeKey = [token ? token : '', POST_STRIPE_SETUP_URL, {}] as ICreatePostFetcher['key'];
  const { trigger } = useSWRMutation(POST_STRIPE_SETUP_URL, () => createPostFetcher(stripeKey), {
    onSuccess: () => {
      mutate();
    }
  });

  const [isZuckMode] = useZuckMode();
  const [zmail] = useZmail();
  const zHostKey = (token && isZuckMode && zmail ? [token, GET_HOST_URL, zmail] : null) as ICreateGetFetcher['key'];
  const { data: zdata } = useSWR<IHostLocApi>(zHostKey);

  useEffect(() => {
    if (isZuckMode && zdata && hostData !== zdata) {
      setHostData(zdata);
    } else if (data !== hostData) {
      setHostData(data);
    }
  }, [data, zdata, isZuckMode]);

  // trigger the polling, to check for new data
  useEffect(() => {
    if (validateFlag && user && !triggered) {
      trigger().then(() => {
        setTriggered(true);
      });
    }
  }, [validateFlag, user, trigger, triggered]);

  // after stripe setup has completed, poll for updated data until `data.stripe_has_ach = true`
  useEffect(() => {
    let count = 0;
    const maxAttempts = 20;
    let interval: ReturnType<typeof setInterval>;
    if (triggered && data && !data.stripe_has_ach && count < maxAttempts) {
      interval = setInterval(() => {
        mutate();
        count += 1;
        if (count >= maxAttempts) {
          clearInterval(interval);
          setUser({ id: user ? user.uid : '', email: user?.email ? user.email : '' });
          captureMessage(` ~ WARNING - stripe not loading new data (stripe_has_ach = true) after validation`);
          // console.log('TIMEOUT validate stripe');
        }
        // console.log('Polling for stripe_has_ach, attempt:', count);
      }, 1000);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [data, mutate, validateFlag]);

  if (validate && !validateFlag) {
    setValidateFlag(true);
  }

  if (error) {
    setUser({ id: user ? user.uid : '', email: user?.email ? user.email : '' });
    captureMessage(` ~ ERROR - could not retreive data from ${GET_HOST_URL} - logged out`);
    captureException(error);
    logout();
  }

  return <ApiHostLocCtx.Provider value={{ hostApiLocInfo: hostData, isApiLocLoading }}>{children}</ApiHostLocCtx.Provider>;
};

export const useApiHostLoc = () => {
  const context = useContext(ApiHostLocCtx);
  if (context === null) {
    throw new Error('useApiHostLoc must be used within a ApiHostLocProvider');
  }
  return context;
};

export interface IHostLocApi {
  email: string;
  first_name: string;
  last_name: string;
  hs_contact_id: string;
  is_admin: boolean;
  stripe_customer_id: string;
  stripe_has_ach: boolean;
  locations: Array<ILocationApi>;
  transactions: Array<ITransaction>;
  // image_url: string;
  // referrals: number;
  // badges: Array<string>;
}

export interface ILocationApi {
  hs_deal_id: string;
  address: IAddress;
  stats: IStat;
  payments: Array<IPayment>;
  // pics: Array<string>;
  // medallion: { id: string; };
  explorer: IExplorer;
}

export interface IAddress {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

interface IStat {
  online: boolean;
  uptime_days: number;
  num_radios: number;
  type: string;
  mount: string;
  direction: string;
  height: string;
  los: string;
  isp: string;
  speed: number;
  latency: number;
}

export interface IPayment {
  date: string;
  earned: number;
}

export interface ITransaction {
  date: string;
  amount: number;
  stripe_id: string;
}

interface IExplorer {
  url: string;
  radio_name: string;
}
