import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';

import Header from './Header';

const MainStyled = tw.main`bg-really-smoke h-full block`;
const Container = tw.div`px-4 lg:px-8 mx-auto max-w-screen-xl py-4 rounded-none shadow-none`;

const Layout: React.FC<PropsWithChildren<{ login: boolean }>> = ({ login, children }) => {
  return (
    <>
      <MainStyled css={[login && tw`bg-black`]}>
        <Container>
          {!login && <Header />}
          {children}
        </Container>
      </MainStyled>
    </>
  );
};

export default Layout;
