export const getHostErrMsgs = {
  400: 'Incorrect host info error, please contact support@really.com',
  401: 'Trouble loading host info error, please contact support@really.com',
  404: 'Cannot find host info error, please contact support@really.com',
  500: 'Host info server error, please contact support@really.com'
};
export const unknownHostErrMsg = 'Host info error, please contact support@really.com';
export const unknownHostErrMsg2 = 'Error Host info, please contact support@really.com';

export const reqPayErrMsgs = {
  400: 'Having issues complete payent, please contact support@really.com',
  401: 'Trouble with payment, please contact support@really.com',
  404: 'Cannot complete payment, please contact support@really.com',
  500: 'Payment did not complete, please contact support@really.com'
};
export const unknownReqPayErrMsg = 'Payment failed, please contact support@really.com';
export const unknownReqPayErrMsg2 = 'Failure with payment, please contact support@really.com';
