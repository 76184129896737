import React, { FC, createContext, Dispatch, SetStateAction, useContext, useState, ReactNode, Context } from 'react';

type SetState<T> = [T, Dispatch<SetStateAction<T>>];

export type Ret<T> = {
  context: Context<SetState<T>>;
  Provider: FC<{ children: ReactNode }>;
  useContextState: () => SetState<T>;
};

export default function createContextState<T>(def: T): Ret<T> {
  const ctx = createContext<[T, Dispatch<SetStateAction<T>>]>([def, () => void 0]);

  return {
    context: ctx,
    Provider(props) {
      const state = useState<T>(def);
      return <ctx.Provider value={state} {...props} />;
    },
    useContextState() {
      return useContext(ctx);
    }
  };
}
