import React, { Suspense } from 'react';
import tw from 'twin.macro';
import { init, ErrorBoundary } from '@sentry/react';

const { VITE_REACT_APP_SENTRY_DSN, MODE } = import.meta.env;
import AppRouter from './AppRouter';
import Spinner from '@components/Spinner';
import { H1, H3 } from '@components/Styles';

const CenteredContainer = tw.div`h-screen flex flex-col items-center justify-center`;

init({
  dsn: VITE_REACT_APP_SENTRY_DSN,
  maxBreadcrumbs: 50,
  debug: MODE === 'development' ? true : false,
  environment: MODE
});

const App: React.FC = () => {
  const UncaughtErrorMsg = (
    <CenteredContainer>
      <H1>An error has occured, try to refresh the page</H1>
      <H3>please contact support@really.com for additional info</H3>
    </CenteredContainer>
  );

  return (
    <ErrorBoundary fallback={UncaughtErrorMsg}>
      <Suspense
        fallback={
          <CenteredContainer>
            <Spinner />
          </CenteredContainer>
        }
      >
        <AppRouter />
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
