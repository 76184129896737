import tw from 'twin.macro';

export const H1 = tw.h1`text-black text-4xl font-black leading-9`;
export const H2 = tw.h2`text-black text-3xl font-black leading-[1.875rem]`;
export const H3 = tw.h3`text-black text-2xl font-black leading-6`;

export const TxtBase = tw.span`text-black text-base font-medium leading-4`;
export const BigNum = tw.span`text-black text-6xl font-black leading-[3.75rem]`;

export const Card = tw.div`flex flex-col items-start grow shrink-0 basis-0 border border-black rounded-sm bg-white`;
// Card2 is a temp solution, for when some cards are missing...
export const Card2 = tw.div`flex flex-col items-start border border-black rounded-sm bg-white`;
