import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';

import { HeaderDropdown } from './Dropdown';
import { useApiHostLoc } from '@context/HostLoc';

import logo from '@images/home/header/logo.svg';
import logodrip from '@images/home/header/logodrip.svg';

import * as SC from '@components/Styles';
const Container = tw.div`pb-4 flex flex-col justify-center self-stretch`;
const StickyHeader = tw.header`sticky top-0 left-0 z-100`;
const HeadContainer = tw.div`flex items-center mx-0`;

const LogoHref = tw.a`flex items-center justify-start cursor-pointer py-1.5 font-normal`;
const ReallyLogo = tw.img`w-[250px] h-[40px] col-start-1 row-start-1 block`;
const ReallyDripLogo = tw(ReallyLogo)`opacity-0 hover:opacity-100 transition-opacity duration-500`;

const NavRightCont = tw.div`block w-full`;
const NavRight = tw.div`flex flex-row justify-end`;
const List = tw.ul`flex flex-row text-xl font-bold mb-0 mt-0 items-center gap-2 md:gap-8`;
const Item = tw.li`flex flex-row gap-1.5 cursor-pointer`; //gap-3

const Header: React.FC<PropsWithChildren<{}>> = () => {
  const { hostApiLocInfo } = useApiHostLoc();
  const { first_name } = Object(hostApiLocInfo);

  return (
    <Container>
      <StickyHeader>
        <HeadContainer>
          <LogoHref href="/">
            <div className="grid">
              <ReallyLogo src={logo} alt="Really Logo" />
              <ReallyDripLogo src={logodrip} alt="Really Logo" />
            </div>
          </LogoHref>

          <NavRightCont>
            <NavRight>
              <List>
                <Item tw="cursor-default hidden md:inline-block">
                  <SC.TxtBase>{`${first_name !== undefined ? `Welcome, ${first_name}!` : ''}`}</SC.TxtBase>
                </Item>
                <HeaderDropdown />
              </List>
            </NavRight>
          </NavRightCont>
        </HeadContainer>
      </StickyHeader>
    </Container>
  );
};

export default Header;
